import React, { Suspense, lazy, useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "../css/calculator.css";
import "../css/style.css";
import CryptoJS from "react-native-crypto-js";

// import loadable from "@loadable/component";

// import Header from "./Header";

// import HeaderLanding from "./HeaderLanding";
// import Main from "./Main";
// import Footer from "./Footer";
// import Footer_uat from "./Footer_uat";
// import FooterLanding from "./FooterLanding";
// import SignIn from "./SignIn";
// import SignUp from "./SignUp";
// import CryptoJS from "react-native-crypto-js";
// import { Helmet } from "react-helmet";

const SignIn = React.lazy(() => import("./SignIn"));
const SignUp = React.lazy(() => import("./SignUp"));

const Header = React.lazy(() => import("./Header"));
const Main = React.lazy(() => import("./Main"));
const Footer_uat = React.lazy(() => import("./Footer_uat"));

// const { REACT_APP_API_ENDPOINT } = process.env.REACT_APP_API_ENDPOINT;

var ciphertext = CryptoJS.AES.encrypt(
  "Basic aGhmbGFwaTpoaGZsQDEyMyNAIQ==",
  "hero housing ltd"
).toString();
console.log("ciphertext::", ciphertext);
const HEADER_AUTH = {
  Authorization: ciphertext,
};
const OTHER_HEADER = {
  "Content-Type": "multipart/form-data",
  Authorization: ciphertext,
};

// const HEADER_AUTH = {
//   Authorization: "Basic aGhmbGFwaTpoaGZsQDEyMyNAIQ==",
// };
// const OTHER_HEADER = {
//   "Content-Type": "multipart/form-data",
//   Authorization: "Basic aGhmbGFwaTpoaGZsQDEyMyNAIQ==",
// };

// const BASE_URL = REACT_APP_API_ENDPOINT;
// console.log("BASE_URLBASE_URLBASE_URLBASE_URLBASE_URLBASE_URL", BASE_URL)
// const FRONT_BASE_URL = process.env.React_App_Front_Base_Url;
// const FILE_BASE_URL = process.env.React_App_File_Base_Url;

// const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
// console.log("BASE_URLBASE_URLBASE_URLBASE_URLBASE_URLBASE_URL",process.env.REACT_APP_API_ENDPOINT)

// const BASE_URL = "http://3.110.253.63:4006";
// const FRONT_BASE_URL = "http:/3.110.253.63:5000";
// const FILE_BASE_URL = "http://3.110.253.63:4006";

// const BASE_URL = process.env.REACT_APP_BASE_URL;
// const FRONT_BASE_URL = process.env.REACT_APP_FRONT_BASE_URL;
// const FILE_BASE_URL = process.env.REACT_APP_BASE_URL;

const BASE_URL = "https://www.herohousingfinance.com/admin";
const FRONT_BASE_URL = "https://www.herohousingfinance.com";
const FILE_BASE_URL = "https://www.herohousingfinance.com";

// const BASE_URL = "https://uat.herohousingfinance.com/admin";
// const FRONT_BASE_URL = "https://uat.herohousingfinance.com";
// const FILE_BASE_URL = "https://uat.herohousingfinance.com";

const App = (props) => {
  const [hidesidebar, setHidesidebar] = useState(false);

  const handlesidebar = (value) => {
    setHidesidebar(value);
  };

  return window.location.href.includes("signin") ? (
    <div id="main">
      <Suspense fallback={<div>loading...</div>}>
        <SignIn />
      </Suspense>
    </div>
  ) : window.location.href.includes("signup") ? (
    <div id="main">
      <Suspense fallback={<div>loading...</div>}>
        <SignUp />
      </Suspense>
    </div>
  ) : (
    <div id="main">
      <Suspense fallback={null}>
        <Header hidesidebar={null} />
      </Suspense>
      <Suspense fallback={null}>
        <Main handlesidebar={handlesidebar} />
      </Suspense>
      <Suspense fallback={null}>
        <Footer_uat />
      </Suspense>
    </div>
  );

  // <div id="main">
  //   <Header hidesidebar={hidesidebar} />
  //   <Main handlesidebar={handlesidebar} />
  //   <Footer_uat />
  // </div>
};

export default App;

export { BASE_URL, FRONT_BASE_URL, FILE_BASE_URL, HEADER_AUTH, OTHER_HEADER };
